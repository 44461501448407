"use client";

import Image from "next/image";
import Link from "next/link";

import logo from "@@/logo.svg";
import {
  ActionIcon,
  Affix,
  Button,
  Container,
  Group,
  Text,
  Transition,
  rem,
} from "@mantine/core";
import { useWindowScroll } from "@mantine/hooks";
import {
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandLinkedin,
  IconBrandTwitter,
} from "@tabler/icons-react";
import { IconArrowUp } from "@tabler/icons-react";

export function Footer({ data }: { data: cats }) {
  const [scroll, scrollTo] = useWindowScroll();

  const groups =
    data &&
    data?.data?.category?.children?.nodes?.map((group, index) => {
      return (
        <Link
          key={index}
          className={
            "trun block text-pretty py-1 text-sm text-Color2 hover:underline"
          }
          href={`/studies/${group.slug}`}
          aria-label={group.name}
          title={group.name}
        >
          {group.name}
        </Link>
      );
    });

  return (
    <footer
      className={
        "border-t border-solid border-Color3 bg-Color3Darken50 pb-4 pt-14"
      }
    >
      <Container
        size={"xl"}
        className={
          "flex justify-between xxs:flex-col xxs:text-center sm:flex-col sm:text-center md:flex-row md:text-right lg:flex-row lg:text-right"
        }
      >
        <div
          className={
            "max-w-[600px] pr-0 xxs:flex xxs:flex-col xxs:items-center sm:flex sm:flex-col sm:items-center md:block md:flex-row md:items-end lg:block lg:flex-row lg:items-end"
          }
        >
          <Image
            src={logo.src}
            height={0}
            width={0}
            style={{ width: "100px !important", height: "50px" }}
            alt="logo"
            sizes="(max-width: 480px) 10vw, (max-width: 800px) 10vw, (max-width: 2048px) 10vw, 10vw"
            loading="lazy"
            placeholder="blur"
            blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
          />
          <Text c="Color1" className={"text-2xl"}>
            مؤسسة البراك لدراسات الجدوى
          </Text>
          <Text size="md" c="Color2">
            المملكة العربية السعودية – جازان – أبوعريش.
          </Text>
          <Text size="md" c="Color2">
            جمهورية مصر العربية –بني سويف الجديدة شرق النيل – الحي الأول .
          </Text>
        </div>
        {/* <div className={classes.wrapper}> */}
        <div className="grid-cols-3 grid-rows-4 gap-1 xxs:hidden lg:!grid">
          {groups}
        </div>
        {/* </div> */}
      </Container>
      {/* <Container size={"xl"} className={classes.afterFooter}> */}
      <Container
        size={"xl"}
        className={
          "mt-10 flex items-center justify-between border-t border-solid border-Color3 pt-5 xxs:flex-col sm:flex-col md:flex-row lg:flex-row"
        }
      >
        <Text c="white" size="sm">
          كل الحقوق محفوظة لشركه البراك لدراسات الجدوى©.
        </Text>
        <Group
          gap={0}
          className={"xxs:mt-5 sm:mt-5"}
          justify="flex-end"
          wrap="nowrap"
        >
          <ActionIcon
            size="lg"
            color="white"
            variant="subtle"
            component="a"
            href="https://www.facebook.com/elbrak1/"
            target="_blank"
            aria-label="facebook"
            className="hover:!text-[#4267B2]"
          >
            <IconBrandFacebook
              style={{ width: rem(18), height: rem(18) }}
              stroke={1.5}
            />
          </ActionIcon>
          <ActionIcon
            size="lg"
            color="white"
            variant="subtle"
            component="a"
            aria-label="Twitter"
            className="hover:!text-[#1DA1F2]"
            href="https://x.com/lbrk170781"
            target="_blank"
          >
            <IconBrandTwitter
              style={{ width: rem(18), height: rem(18) }}
              stroke={1.5}
            />
          </ActionIcon>
          <ActionIcon
            size="lg"
            color="white"
            variant="subtle"
            component="a"
            aria-label="instagram"
            className="hover:!text-[#E1306C]"
            href="https://www.instagram.com/elbrak378/"
          >
            <IconBrandInstagram
              style={{ width: rem(18), height: rem(18) }}
              stroke={1.5}
            />
          </ActionIcon>
          <ActionIcon
            size="lg"
            color="white"
            variant="subtle"
            component="a"
            aria-label="linkedin"
            className="hover:!text-[#0a66c2]"
            href="https://www.linkedin.com/in/%D8%B4%D8%B1%D9%83%D8%A9-%D8%A7%D9%84%D8%A8%D8%B1%D8%A7%D9%83-%D9%84%D8%AE%D8%AF%D9%85%D8%A7%D8%AA-%D8%AF%D8%B1%D8%A7%D8%B3%D8%A9-%D8%A7%D9%84%D8%AC%D8%AF%D9%88%D9%89-9227222b3"
          >
            <IconBrandLinkedin
              style={{ width: rem(18), height: rem(18) }}
              stroke={1.5}
            />
          </ActionIcon>
        </Group>

        <Affix position={{ bottom: 20, left: 20 }}>
          <Transition transition="slide-up" mounted={scroll.y > 0}>
            {(transitionStyles) => (
              <Button
                variant="filled"
                className="!me-0 !content-center !items-center !justify-center bg-Color3Darken30 !text-center"
                style={transitionStyles}
                onClick={() => scrollTo({ y: 0 })}
              >
                {" "}
                <IconArrowUp
                  className="!me-0 !text-center"
                  style={{ width: rem(16), height: rem(16) }}
                />
              </Button>
            )}
          </Transition>
        </Affix>
      </Container>
    </footer>
  );
}
