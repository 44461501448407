const config = {
  API_HOST: process.env.API_HOST,
  API_KEY: process.env.API_KEY,
  APP_URL: process.env.APP_URL,
  WTSAPP_NUM: process.env.NEXT_PUBLIC_WTSAPP_NUM,
  PHONE_NUM: process.env.NEXT_PUBLIC_PHONE_NUM,
  ADMIN_EMAIL: process.env.ADMIN_EMAIL,
  RESEND_API_KEY: process.env.RESEND_API_KEY,
};

export default config;
