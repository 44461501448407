"use client";

import Image from "next/image";
import Link from "next/link";
import { usePathname, useRouter } from "next/navigation";
import { SetStateAction, useEffect, useState } from "react";
import React from "react";

import {
  Badge,
  Burger,
  Button,
  Center,
  Container,
  Group,
  Loader,
  Menu,
  Overlay,
  SimpleGrid,
  Space,
  Text,
  Title,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { Spotlight, spotlight } from "@mantine/spotlight";
import { IconSearch } from "@tabler/icons-react";
import {
  IconBrandWhatsapp,
  IconMailFilled,
  IconPhoneCall,
} from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { AppRouterInstance } from "next/dist/shared/lib/app-router-context.shared-runtime";

import ExtractSRC from "@/app/_utils/ExtractSRC";
import HtmlToStr from "@/app/_utils/HtmlToStr";
import config from "@/config";

import logo from "../../../public/logo.svg";
import MinMenu from "../MinMenu/MinMenu";
import dataSearch from "./dataSearch.json";
import links from "./links.json";

export function Header({ data }: { data: cats }) {
  const [sdata, setSdata] = useState(dataSearch);
  // @ts-ignore: Unreachable code error
  const [searchLoading, setSearchLoading] = useState(false);

  const [opened, { toggle, close }] = useDisclosure(false);

  // @ts-ignore: Unreachable code error
  const [active, setActive] = useState(links[0].link);
  const router = useRouter();
  const pathname = usePathname();

  const [squery, setSquery] = useState("");

  const searchQuery = useQuery({
    queryKey: ["search", squery],
    queryFn: async () => {
      if (squery.length === 0) {
        return dataSearch;
      }
      const instance = axios.create({
        baseURL: config.API_HOST,
        timeout: 40000,
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json; charset=utf-8",
          Authorization: "Bearer " + config.API_KEY,
        },
      });
      const res = await instance.get(
        `${config.API_HOST}apigrahql?query=${encodeURI(searchQueryF(squery))}`
      );
      return res.data;
    },
    enabled: squery.length > 0,
    refetchOnWindowFocus: false,
    staleTime: 5 * 60 * 1000, // 5 minutes
  });

  useEffect(() => {
    if (searchQuery.data && searchQuery.data?.data?.posts?.nodes) {
      AppendSearchResult(searchQuery.data?.data?.posts?.nodes, setSdata);
    } else if (squery.length === 0) {
      setSdata(dataSearch);
    }
  }, [searchQuery.data, squery]);

  const sitems = sdata
    .filter((item) =>
      item.title.toLowerCase().includes(squery.toLowerCase().trim())
    )
    .map((item, i) => (
      <Spotlight.Action
        w="100%"
        className="p-10"
        key={i}
        onClick={(event) => {
          event.preventDefault();
          router.push(`${item.link}`);
          setActive(item.link);
        }}
      >
        <Group wrap="nowrap" w="100%">
          <div style={{ flex: 1 }}>
            <Title
              order={1}
              size={"h6"}
              textWrap="balance"
              lineClamp={1}
              className="justify-start !pb-1 !text-right !text-lg !text-Color3"
            >
              {item.title}
            </Title>

            {item.description && (
              <Text
                lineClamp={2}
                // opacity={0.5}
                size="xs"
                className="justify-center !text-right !text-xs !text-gray-500"
              >
                {item.description}
              </Text>
            )}
          </div>

          {item.new && (
            <Badge color="Color4" className="!bg-Color4 !text-white">
              جديد
            </Badge>
          )}
        </Group>
      </Spotlight.Action>
    ));

  const items = MenuItems(pathname, router, setActive, data && data, close);

  return (
    <>
      <div
        className={
          "!P-0 fixed left-0 top-[50%] z-[999] h-auto w-auto min-w-[10px] translate-y-[-50%]"
        }
      >
        <div
          className={
            "group relative h-[50px] w-[60px] cursor-pointer bg-[#25d366] text-white transition-all duration-200"
          }
        >
          <a
            aria-label="تواصل معنا عبر الواتس اب"
            href={`https://api.whatsapp.com/send/?phone=${config.WTSAPP_NUM}&text=اود الحصول علي دراسه&type=phone_number&app_absent=0`}
          >
            <span className={"absolute left-[10px] top-[5px] inline-block"}>
              <IconBrandWhatsapp size={"lg"} width={40} height={40} />
            </span>
            <span
              className={
                "invisible absolute left-[55px] top-[50%] inline-block h-[100%] w-auto min-w-[100px] translate-y-[-50%] bg-[#25d366] p-[10px] leading-8 opacity-0 transition-all duration-200 group-hover:visible group-hover:opacity-100"
              }
            >
              تواصل معنا
            </span>
          </a>
        </div>
        <div
          className={
            "group relative h-[50px] w-[60px] cursor-pointer bg-Color4 text-white transition-all duration-200"
          }
        >
          <Link href="/contactus" aria-label="تواصل معنا عبر الايميل">
            <span className={"absolute left-[10px] top-[5px] inline-block"}>
              <IconMailFilled size={"lg"} width={40} height={40} />
            </span>
            <span
              className={
                "invisible absolute left-[55px] top-[50%] inline-block h-[100%] w-auto min-w-[100px] translate-y-[-50%] bg-Color4 p-[10px] leading-8 opacity-0 transition-all duration-200 group-hover:visible group-hover:opacity-100"
              }
            >
              راسلنا
            </span>
          </Link>
        </div>
        <div
          className={
            "group relative h-[50px] w-[60px] cursor-pointer bg-Color3 text-white transition-all duration-200"
          }
        >
          <a
            href={`tel:${config.PHONE_NUM}`}
            target="_blank"
            aria-label="تواصل معنا عبر الهاتف"
          >
            <span className={"absolute left-[10px] top-[5px] inline-block"}>
              <IconPhoneCall size={"lg"} width={40} height={40} />
            </span>
            <span
              className={
                "invisible absolute left-[55px] top-[50%] inline-block h-[100%] w-auto min-w-[100px] translate-y-[-50%] bg-Color3 p-[10px] leading-8 opacity-0 transition-all duration-500 group-hover:visible group-hover:opacity-100"
              }
            >
              اتصل بنا
            </span>
          </a>
        </div>
      </div>
      <header
        className="!fixed top-0 z-[99999] w-full border-b-[1px] border-b-Color2Lighten30 bg-Color1Lighten pb-[1px] pt-[5px] bg-blend-darken shadow-md"
        dir="rtl"
      >
        <Container size="xl" className={"flex items-center justify-between"}>
          <Group>
            <Link
              href="/"
              className="relative h-[60px] w-[100px]"
              aria-label="شركة البراك لخدمات دراسات الجدوى"
            >
              <Image
                src={logo.src}
                alt="شركة البراك لخدمات دراسات الجدوى"
                height={0}
                width={0}
                sizes="(max-width: 480px) 10vw, (max-width: 800px) 10vw, (max-width: 2048px) 10vw, 10vw"
                style={{ width: "100px !important", height: "auto !important" }}
                loading="lazy"
                className="!mt-[-5px]"
                placeholder="blur"
                blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
              />
            </Link>
          </Group>
          <Group gap={8} visibleFrom="sm">
            {items}
          </Group>
          <Group gap={5} visibleFrom="sm">
            <Button
              variant="filled"
              color="Color3"
              radius="lg"
              className="!px-5"
              onClick={spotlight.open}
            >
              <IconSearch size={12} />
              <Space w={"10px"} />
              بحث
            </Button>
          </Group>

          <Group hiddenFrom="sm">
            <Button
              variant="filled"
              color="Color3"
              radius="lg"
              className="!px-5"
              onClick={spotlight.open}
            >
              <IconSearch size={12} />
              <Space w={"10px"} />
              بحث
            </Button>
          </Group>

          <Burger
            aria-label="القائمة"
            opened={opened}
            onClick={toggle}
            hiddenFrom="sm"
            size="sm"
          />
        </Container>
      </header>
      <MinMenu opened={opened} toggle={toggle} data={data} isLoading={false} />

      <Spotlight.Root
        shortcut="shift + K"
        query={squery}
        scrollable
        onQueryChange={setSquery}
        classNames={{
          search:
            "bg-white !rtl !text-right rounded-none py-[30px] px-[50px] border-0 border-b-0 !text-Color3 placeholder:text-Color3",
          actionsList: "!relative !bg-Color1 !bg-blend-lighten !p-0",
          overlay: "z-[99999999]",
          inner: "z-[999999999]",
          action:
            "bg-Color1 bg-blend-lighten hover:bg-Color1Lighten60 transition-all duration-300 !border-b-white border-solid !border-b px-[10px] py-[15px]",
        }}
      >
        <Spotlight.Search
          placeholder={"هل تبحث عن دراسة جدوى؟ ..."}
          leftSection={<IconSearch className="!text-Color3" stroke={1.5} />}
        />
        <Spotlight.ActionsList dir="rtl !text-right">
          {searchLoading ? (
            <Spotlight.Empty className="p-5">
              <Loader
                className="float-right !pl-8"
                color="Color4"
                size={"xs"}
              />
              <Text c={"Color3"}>جاري البحث برجاء الانتظار ....</Text>
            </Spotlight.Empty>
          ) : sitems.length > 0 ? (
            sitems
          ) : (
            <Spotlight.Empty className="p-5">
              <Text c={"Color4"}>لم نجد نتيجه مطابقه لبحثك حاول مره اخري!</Text>
            </Spotlight.Empty>
          )}
        </Spotlight.ActionsList>
      </Spotlight.Root>
    </>
  );
}
function MenuItems(
  pathname: string,
  router: string[] | AppRouterInstance,
  setActive: { (value: SetStateAction<string>): void; (arg0: string): void },
  data: cats,
  close: { (): void; (): void }
) {
  return links.map((link) => {
    if (link.link == "/studies") {
      return (
        <Menu
          key={link.label}
          classNames={{
            dropdown:
              "!w-[95%] p-[10px] pt-[5px] bg-Color1 bg-blend-lighten !rounded-none !border-0 items-center justify-center !left-[2.5%]",
            item: "relative overflow-hidden !rounded-md !p-0 !transition-all ease-in-out !duration-200",
            itemLabel: "!relative !h-full",
          }}
          // width={"100%"}
          shadow="xl"
          position="bottom"
          offset={15}
          // withArrow={true}
          // arrowPosition="center"
          zIndex="999999"
          transitionProps={{ transition: "fade-down", duration: 250 }}
          trigger="hover"
          // openDelay={0}
          // closeDelay={0}
        >
          <Menu.Target>
            <Link
              href="#"
              color="Color4"
              aria-label={link.label}
              title={link.label}
              className={
                "text-md relative block cursor-pointer rounded-md px-3 py-2 font-medium leading-none text-Color2Darken70 no-underline transition-all duration-300 hover:bg-Color1Darken [&[data-active]]:bg-Color4 [&[data-active]]:text-white"
              }
              data-active={
                pathname.substring(0, 8) == link.link ||
                pathname.substring(0, 7) == "/studie/"
                  ? true
                  : undefined
              }
              onClick={(event) => {
                event.preventDefault();
                close();
                router.push(`${link.link}`);
                setActive(link.link);
              }}
            >
              {link.label}
            </Link>
          </Menu.Target>

          <Menu.Dropdown role="menu">
            <SimpleGrid
              cols={data && data?.errors ? 1 : 5}
              spacing="xs"
              verticalSpacing="xs"
            >
              {data && data?.errors ? (
                <>
                  <Center w={"100%"} h={"100%"}>
                    {data && data?.errors[0]?.message}
                    <Loader color="Color4" size="md" />
                  </Center>
                </>
              ) : (
                data &&
                data?.data?.category?.children?.nodes?.map(
                  (
                    cat: { name: string; slug: string; description: string },
                    i: number
                  ) => (
                    <Menu.Item
                      key={i}
                      color="Color3"
                      className={"group md:h-[14vw] lg:h-[12vw] xl:h-[11.5vw]"}
                      data-active={
                        pathname == `/studies/${decodeURIComponent(cat.slug)}`
                          ? true
                          : undefined
                      }
                      onClick={(event) => {
                        event.preventDefault();
                        router.push(`/studies/${decodeURIComponent(cat.slug)}`);
                        setActive(
                          `/studies/cat.id/decodeURIComponent(cat.slug)`
                        );
                      }}
                      component={Link}
                      aria-label={cat.name}
                      title={cat.name}
                      href={`/studies/${decodeURIComponent(cat.slug)}`}
                    >
                      <Overlay
                        color="black"
                        // opacity={0.8}
                        blur={1}
                        zIndex={100}
                        className="opacity-80 transition-all duration-500 group-hover:opacity-40 group-hover:blur-none"
                      />
                      <Image
                        src={ExtractSRC(cat?.description) || ""}
                        alt={cat.slug}
                        fill
                        style={{ objectFit: "cover" }}
                        sizes="(max-width: 480px) 10vw, (max-width: 800px) 10vw, (max-width: 2048px) 10vw, 10vw"
                        loading="lazy"
                        placeholder="blur"
                        blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                      />
                      <Center className="absolute h-full w-full">
                        <Text
                          c={"Color1"}
                          className="bottom-10 z-[101] w-full text-center !text-xl !font-bold text-white"
                        >
                          {cat.name}
                        </Text>
                      </Center>
                    </Menu.Item>
                  )
                )
              )}
            </SimpleGrid>
          </Menu.Dropdown>
        </Menu>
      );
    } else {
      return (
        <Link
          key={link.label}
          color="Color4"
          href={link.link}
          aria-label={link.label}
          title={link.label}
          className={
            "text-md relative block cursor-pointer rounded-md px-3 py-2 font-medium leading-none text-Color2Darken70 no-underline transition-all duration-300 hover:bg-Color1Darken [&[data-active]]:bg-Color4 [&[data-active]]:text-white"
          }
          data-active={pathname == link.link ? true : undefined}
          onClick={(event) => {
            event.preventDefault();
            router.push(`${link.link}`);
            setActive(link.link);
          }}
        >
          {link.label}
        </Link>
      );
    }
  });
}

function AppendSearchResult(
  search: any[],
  setSdata: {
    (
      value: SetStateAction<
        { link: string; title: string; description: string; new: boolean }[]
      >
    ): void;
    (arg0: any): void;
  }
) {
  const newArray = search.map((item) => ({
    link: `/${encodeURIComponent(item.slug)}`,
    title: item.title,
    description: HtmlToStr(item.excerpt, 10),
    new: false,
  }));

  setSdata(newArray);
}

function searchQueryF(query = "") {
  const q = `
  {
  posts(where: {search: "${query}", categoryIn: [1, 5]}, first: 20) {
    nodes {
      slug
      excerpt
      title
    }
  }
}
  `;
  return q;
}
