// Import the links data
import Link from "next/link";
import { usePathname, useRouter } from "next/navigation";

import { Accordion, Drawer, Loader } from "@mantine/core";
import { AppRouterInstance } from "next/dist/shared/lib/app-router-context.shared-runtime";

import links from "../Header/links.json";

interface MinMenuProps {
  opened: boolean;
  toggle: () => void;
  data: any;
  isLoading: boolean;
}
const MinMenu: React.FC<MinMenuProps> = ({
  opened,
  toggle,
  data,
  isLoading,
}) => {
  const router = useRouter();

  const items = MenuItems(router, isLoading, data, toggle);

  return (
    <Drawer
      opened={opened}
      onClose={toggle}
      position="left"
      // className="z-[99999999]"
      zIndex={9999}
      offset={0}
      classNames={{
        inner: "top-[66px]",
        content: "bg-[#536493] !p-0",
        body: "!p-0",
      }}
      // offset={20}
      size="xs"
      overlayProps={{ backgroundOpacity: 0.5, blur: 4 }}
      withCloseButton={false}
      transitionProps={{
        transition: "fade-left",
        duration: 150,
        timingFunction: "ease",
      }}
    >
      {items}
    </Drawer>
  );
};

function MenuItems(
  router: AppRouterInstance | string[],
  isLoading: boolean,
  data: cats,
  close: { (): void; (): void }
) {
  const pathname = usePathname();
  return links.map((link) => {
    if (link.link == "/studies") {
      return (
        <Accordion
          dir="rtl"
          key={link.label}
          chevronPosition="left"
          defaultValue="studies"
          classNames={{
            item: "!border-Color3Darken30",
            chevron: "absolute left-0 inline-block",
            content: "!p-0",
          }}
        >
          <Accordion.Item value="photos">
            <Accordion.Control
              className={
                "text-md font-500 relative block cursor-pointer rounded-none !border-b-[1px] !border-Color3Darken30 px-[10px] py-[20px] pr-[30px] font-medium leading-none text-Color1Lighten no-underline transition-all duration-300 hover:bg-Color3Darken35 [&[data-active]]:bg-Color4 [&[data-active]]:text-white"
              }
              data-active={
                pathname.substring(0, 8) == link.link ||
                pathname.substring(0, 7) == "/studie/"
                  ? true
                  : undefined
              }
            >
              <Link
                href="#"
                color="Color4"
                title={link.label}
                aria-label={link.label}
                onClick={(event) => {
                  event.preventDefault();
                  close();
                  router.push(`${link.link}`);
                }}
              >
                {link.label}
              </Link>
            </Accordion.Control>
            <Accordion.Panel>
              {isLoading ? (
                <Loader color="Color4" size="md" />
              ) : data?.errors ? (
                <>
                  {data?.errors[0]?.message}
                  <Loader color="Color4" size="md" />
                </>
              ) : (
                data?.data &&
                data?.data?.category?.children?.nodes?.map((cat, i) => (
                  <Link
                    key={i}
                    color="Color3"
                    aria-label={cat.name}
                    title={cat.name}
                    data-active={
                      pathname == `/studies/${decodeURIComponent(cat.slug)}`
                        ? true
                        : undefined
                    }
                    className={
                      "text-md font-500 relative block cursor-pointer rounded-none border-b-[1px] border-Color3Darken30 px-[10px] py-[20px] pr-[30px] font-medium leading-none text-Color1Lighten no-underline transition-all duration-300 hover:bg-Color3Darken35 [&[data-active]]:bg-Color4 [&[data-active]]:text-white"
                    }
                    onClick={(event) => {
                      event.preventDefault();
                      close();
                      router.push(`/studies/${decodeURIComponent(cat.slug)}`);
                    }}
                    href={`/studies/${decodeURIComponent(cat.slug)}`}
                  >
                    {cat.name}
                  </Link>
                ))
              )}
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      );
    } else {
      return (
        <Link
          key={link.label}
          color="Color4"
          aria-label={link.label}
          title={link.label}
          data-active={pathname == link.link ? true : undefined}
          href={link.link}
          className={
            "text-md font-500 relative block cursor-pointer rounded-none border-b-[1px] border-Color3Darken30 px-[10px] py-[20px] pr-[30px] font-medium leading-none text-Color1Lighten no-underline transition-all duration-300 hover:bg-Color3Darken35 [&[data-active]]:bg-Color4 [&[data-active]]:text-white"
          }
          onClick={(event) => {
            event.preventDefault();
            close();
            router.push(`${link.link}`);
          }}
        >
          {link.label}
        </Link>
      );
    }
  });
}

export default MinMenu;
