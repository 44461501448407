import(/* webpackMode: "eager", webpackExports: ["DirectionProvider"] */ "/root/elbrak/node_modules/@mantine/core/esm/core/DirectionProvider/DirectionProvider.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["ColorSchemeScript"] */ "/root/elbrak/node_modules/@mantine/core/esm/core/MantineProvider/ColorSchemeScript/ColorSchemeScript.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["MantineProvider"] */ "/root/elbrak/node_modules/@mantine/core/esm/core/MantineProvider/MantineProvider.mjs");
;
import(/* webpackMode: "eager" */ "/root/elbrak/node_modules/@mantine/core/styles.css");
;
import(/* webpackMode: "eager" */ "/root/elbrak/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/root/elbrak/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/root/elbrak/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/root/elbrak/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/root/elbrak/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Cairo\",\"arguments\":[{\"subsets\":[\"arabic\"],\"weight\":[\"500\"],\"variable\":\"--font-cairo\",\"display\":\"swap\",\"preload\":false}],\"variableName\":\"cairo\"}");
;
import(/* webpackMode: "eager" */ "/root/elbrak/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/elbrak/src/app/queryprovider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Footer"] */ "/root/elbrak/src/components/Footer/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/root/elbrak/src/components/Header/Header.tsx");
